@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Poppins:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    @apply transition-colors duration-200 ease-linear;

}

body {
  font-family: "Poppins", "san-serif";
  margin: 0;
  padding: 0;
  @apply overflow-x-hidden bg-white dark:bg-slate-900;

}

img {
  display: block;
  max-width: 100%;
  height: auto;
}